import React, { useRef, useState } from "react";
import "./quotationForm.css";
import Select from "react-select";
import { sendMail } from "../../apis/mail";

const QuotationForm = () => {
  const [selectedOption, setSelectedOption] = useState("");

  let nameRef = useRef(null);
  let mailRef = useRef(null);
  let numRef = useRef(null);
  let subRef = useRef(null);
  let selectRef = useRef(null);
  let desRef = useRef(null);

  const Options = [
    {
      value: "Website Development (Custom Coding)",
      label: "Website Development (Custom Coding)",
    },
    {
      value:
        "Website Creation (Wordpress, WooCommerce, Wix, godady, Google sites)",
      label:
        "Website Creation (Wordpress, WooCommerce, Wix, godady, Google sites)",
    },
    {
      value: "App Development (Android, iOS)",
      label: "App Development (Android, iOS)",
    },
    { value: "Game Development", label: "Game Development" },
    { value: "Social Media Management", label: "Social Media Management" },
    { value: "Social Media Marketing", label: "Social Media Marketing" },
    { value: "UI/UX Designing", label: "UI/UX Designing" },
    {
      value:
        "Graphic designing (Logo Creation, Banner Designing, NFT's Creation, etc)",
      label:
        "Graphic designing (Logo Creation, Banner Designing, NFT's Creation, etc)",
    },
    {
      value: " Video Creation (Whiteboard Animation, 2D, 3D, Promo videos)",
      label: " Video Creation (Whiteboard Animation, 2D, 3D, Promo videos)",
    },
  ];

  var handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  const HandleClick = (event) => {
    event.preventDefault();
    const data = {
      name: nameRef.current.value,
      email: mailRef.current.value,
      number: numRef.current.value,
      subject: subRef.current.value,
      select: selectedOption,
      description: desRef.current.value,
    };
    console.log(data);
    Mail(data);
  };

  const Mail = (data) => {
    sendMail(data).then((data) => {
      // if (data.error) {
      //   // setError(data.error);
      //   console.log(data.error);
      // } else {
      //   // setAnime(data.data);
      // }
    });
  };

  return (
    <div>
      <center>
        <div className="outer">
          <div className="login-box">
            <h2>Get a Quote</h2>
            <form>
              <div className="user-box">
                <input ref={nameRef} type="text" name required />
                <label>Username</label>
              </div>
              <div className="user-box">
                <input ref={mailRef} type="email" name required />
                <label>E-mail</label>
              </div>
              <div className="user-box">
                <input ref={numRef} type="contact" name required />
                <label>WhatsApp</label>
              </div>
              <div className="user-box">
                <input ref={subRef} type="text" name required />
                <label>Subject</label>
              </div>
              <Select
                closeMenuOnSelect={false}
                inputId="select-input"
                placeholder="Please select..."
                //   defaultValue={[colourOptions[0], colourOptions[1]]}
                isMulti
                onChange={handleChange}
                options={Options}
                // styles={colourStyles}
              />
              {/* <div className="drop">
                <label>
                  Multi-select
                  <input
                    mbsc-input
                    id="demo-multiple-select-input"
                    placeholder="Please select..."
                    data-dropdown="true"
                    data-input-style="outline"
                    data-label-style="stacked"
                    data-tags="true"
                  />
                </label>
                
                <select id="demo-multiple-select" multiple>
                  <option value={1}>Books</option>
                  <option value={2}>Movies, Music &amp; Games</option>
                  <option value={3}>Electronics &amp; Computers</option>
                  <option value={4}>Home, Garden &amp; Tools</option>
                  <option value={5}>Health &amp; Beauty</option>
                  <option value={6}>Toys, Kids &amp; Baby</option>
                  <option value={7}>Clothing &amp; Jewelry</option>
                  <option value={8}>Sports &amp; Outdoors</option>
                </select>
              </div> */}
              <div className="user-box">
                <textarea
                  ref={desRef}
                  id="subject"
                  name="subject"
                  placeholder="Write something.."
                  style={{
                    height: "110px",
                    marginTop: "35px",
                    width: "100%",
                    borderRadius: "10px",
                    marginRight: "190px",
                    resize: "vertical",
                  }}
                  defaultValue={""}
                />
                <label>Discription</label>
              </div>
              <a onClick={HandleClick} href="#">
                <span />
                <span />
                <span />
                <span />
                Submit
              </a>
            </form>
          </div>
        </div>
      </center>
    </div>
  );
};

export default QuotationForm;
