import React from "react";
import "./Footer.css";
import { Fade } from "react-reveal";
import { greeting } from "../../portfolio.js";
import Button from "../button/Button";
/* eslint-disable jsx-a11y/accessible-emoji */

export default function Footer(props) {
  return (
    <div className="footer-div">
      <Fade>
        <div className="footer-row">
          <p className="textTopHeaderLeft">support@zokatech.com</p>
          <p className="textTopHeaderCenter">Whatsapp: +92-304-7349691</p>
          <p className="textTopHeaderRight">
            Working Hours: 9:00 - 6:00 (GMT+5)
          </p>
          {/* <p
            className="footer-text"
            style={{ color: props.theme.secondaryText }}
          >
            Contact@zokatech.com
          </p>
          <p
            className="footer-text"
            style={{ color: props.theme.secondaryText }}
          >
            Made with <span role="img">❤️</span> by {greeting.title}
          </p>
          <p
            className="footer-text"
            style={{ color: props.theme.secondaryText }}
          >
            WhatsApp: +91-9888888888
          </p>
          <p
            className="footer-text"
            style={{ color: props.theme.secondaryText }}
          >
            workinng horus: 9:00 AM - 6:00 PM
          </p> */}
        </div>
        {/* <Button
          text="⭐ Star Us On Github"
          newTab={true} */}
        {/* // href={greeting.portfolio_repository}
          // theme={theme}
          // className="portfolio-repo-btn"s
        /> */}
        {/* <ToggleSwitch theme={props.theme} onToggle={props.onToggle}/> */}
      </Fade>
    </div>
  );
}
